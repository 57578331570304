<script setup lang="ts">
import { computed, ref, type PropType } from 'vue';
import TransactionVue from './Transaction.vue'
import type { Transaction } from './types';
import { fieldDesign } from '@/const';

const props = defineProps({
  transactions: {
    type: Array as PropType<Transaction[]>,
    required: true
  },
  cashAccount: {
    type: Object,
    required: false
  },
  editable: {
    type: Boolean,
    default: true
  },
  flavour: {
    type: String,
    default: 'fix'
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

defineEmits([
  'createInstrument',
  'changeTransactionType',
  'delete',
  'split',
  'action'
]);

const sel = ref<number | null>(null);

const selectAll = (value: any) => {
  props.transactions.forEach((transaction: Transaction) => {
    transaction.control.isSelected = !!value;
  });
}

const allSelected = computed(() => {
  const selected = props.transactions.filter((transaction: Transaction) => transaction.control.isSelected)
  return selected.length > 0 && selected.length < props.transactions.length;
});

const countSelected = computed(() => {
  return props.transactions.filter((transaction: Transaction) => transaction.control.isSelected).length;
});


const actions = computed(() => {
  return [{
    value: 'import',
    title: 'Impordi tehingud',
    visible: props.flavour === 'import',
    color: 'success',
    disabled: countSelected.value === 0
  }, {
    value: 'fix',
    title: 'Märgi parandatuks',
    visible: props.flavour === 'fix',
    disabled: countSelected.value === 0,
    color: 'success'
  }, {
    value: 'edit',
    title: 'Muuda korraga',
    visible: true,
    disabled: countSelected.value === 0,
    color: 'primary',
    buttonText: 'Edasi'
  }, {
    value: 'delete',
    title: 'Kustuta',
    visible: true,
    disabled: countSelected.value === 0,
    color: 'error'
  }].filter((action) => action.visible);
});

const selectedAction = ref(actions.value[0].value);

const selectedActionOpts = computed(() => {
  return actions.value.find((action) => {
    return action.value === selectedAction.value;
  });
});
</script>

<template>
  <div v-if="editable" class="px-2 py-3 bg-green-lighten-4 d-flex flex-column flex-sm-row ga-2 align-sm-center">
    <v-checkbox @update:modelValue="selectAll" density="compact" label="Vali kõik" :indeterminate="allSelected" hide-details />
    <div class="d-flex ga-2 align-center">
      <div>
        <v-select style="min-width:200px;" color="primary" v-model="selectedAction" :items="actions" density="compact" v-bind="(fieldDesign as any)" />
      </div>
      <div>
        <v-btn @click="$emit('action', selectedAction)" :color="selectedActionOpts?.color" :disabled="isLoading || selectedActionOpts?.disabled" :isLoading="isLoading">
          {{ selectedActionOpts?.buttonText || 'Kinnita' }}
        </v-btn>
      </div>
    </div>
  </div>
  <v-expansion-panels v-model="sel">
    <TransactionVue
      v-for="(_, i) in transactions"
      v-model="transactions[i]"
      :key="transactions[i].uuid + '-'+ i"
      :uniqueKey="transactions[i].uuid + '-'+ i"
      @createInstrument="$emit('createInstrument', $event)"
      @delete="$emit('delete', transactions[i])"
      @split="$emit('split', transactions[i])"
      :editable="editable"
      :cashAccount="cashAccount"
    />
  </v-expansion-panels>
  <div class="pa-6" v-if="transactions.length === 0">
    Ühtegi tehingut ei leitud
  </div>
</template>
