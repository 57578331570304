<script setup lang="ts">
import { computed, ref } from 'vue';
import { CashflowPeriod, PerformancePeriod, ReportGroupingType } from '~/enums';
import { useI18n } from 'vue-i18n';
import ButtonToggle from './inputs/ButtonToggle.vue';
import DisplayCard from './shared/DisplayCard.vue';
import DateField from '@/components/inputs/DateField.vue';
import { fieldDesign } from '@/const';
import dayjs from 'dayjs';
import { watch } from 'vue';
import GroupByField from './inputs/GroupByField.vue';

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: null,
    default: PerformancePeriod.ThisMonth
  },
  showMonetaryValue: {
    type: Boolean,
    default: true
  },
  groupByValue: {
    default: ReportGroupingType.AssetClass
  },
  chartTypeValue: {
    type: String,
    default: 'value'
  },
  intervalValue: {
    type: String,
    default: 'month'
  },
  showOpenAndClosedPositions: {
    type: Boolean,
    default: true
  },
  isSummary: {
    type: Boolean,
    default: false
  },
  isMonetaryValueEnabled: {
    type: Boolean,
    default: false
  },
  isChartTypeEnabled: {
    type: Boolean,
    default: false
  },
  isIntervalsEnabled: {
    type: Boolean,
    default: false
  },
  flavour: {
    type: String,
    default: 'past'
  },
  range: {
    type: Object,
    default: () => ({
      startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD')
    })
  }
});

const showRangePicker = ref(false);
const rangeForm = ref({
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD')
});

const isOpenPositionsFeatureEnabled = computed(() => false);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (value === 'custom') {
      showRangePicker.value = true
      return
    }
    emit('update:modelValue', value)
  }
});

const groupBy = computed({
  get() {
    return props.groupByValue
  },
  set(value) {
    emit('update:groupByValue', value)
  }
});

const showMonetaryValue = computed({
  get() {
    return props.showMonetaryValue
  },
  set(value) {
    emit('update:showMonetaryValue', value)
  }
});

const showOpenAndClosedPositions = computed({
  get() {
    return props.showOpenAndClosedPositions
  },
  set(value) {
    emit('update:showOpenAndClosedPositions', value)
  }
});

const chartType = computed({
  get() {
    return props.chartTypeValue
  },
  set(value) {
    emit('update:chartTypeValue', value)
  }
});

const interval = computed({
  get() {
    return props.intervalValue
  },
  set(value) {
    emit('update:intervalValue', value)
  }
});

const summaryRange = computed({
  get() {
    return props.range
  },
  set(value) {
    emit('update:range', {
      startDate: value.startDate,
      endDate: value.endDate
    })
  }
});

watch(() => props.range, (value) => {
  rangeForm.value.startDate = value.startDate
  rangeForm.value.endDate = value.endDate
}, { immediate: true });

const emit = defineEmits([
  'update:modelValue',
  'update:groupByValue',
  'update:showMonetaryValue',
  'update:showOpenAndClosedPositions',
  'update:chartTypeValue',
  'update:range',
  'update:intervalValue'
]);

const periodOptions = computed(() => {
  const rangeDate = `${summaryRange.value.startDate} - ${summaryRange.value.endDate}`;
  const transalationKey = props.flavour === 'future' ? 'cashflowPeriod' : 'performancePeriod';
  const custom = props.modelValue === PerformancePeriod.Custom ? rangeDate : t(`${transalationKey}.custom`);
  const periods = props.flavour === 'future' ? Object.values(CashflowPeriod) : Object.values(PerformancePeriod);

  const list = Object.values(periods).map((value) => {
    return {
      value,
      title: value !== PerformancePeriod.Custom ? t(`${transalationKey}.${value}`) : custom
    }
  });
  return list
})

const chartTypeOptions = [
  {
    value: 'value',
    title: t('performance_bar.value')
  },
  {
    value: 'price',
    title: t('performance_bar.price')
  }
];

const intervalOptions = [
  {
    value: 'month',
    title: t('performance_bar.month')
  },
  {
    value: 'quarter',
    title: t('performance_bar.quarter')
  },
  {
    value: 'year',
    title: t('performance_bar.year')
  }
];

const onClick = (item: any) => {
  if (item.value === PerformancePeriod.Custom) {
    showRangePicker.value = true
    return
  }
}

const chooseRange = () => {
  showRangePicker.value = false
  summaryRange.value = rangeForm.value
  emit('update:modelValue', 'custom')
}
</script>

<template>
  <v-card elevation="0" class="bg-transparent" variant="text">
    <div class="d-flex ga-5 flex-column flex-md-row">
      <div class="d-flex flex-column flex-sm-row ga-3 me-md-auto">
        <div id="performance-period-select">
          <v-select
            v-model="value"
            :items="periodOptions"
            hide-details
            variant="outlined"
            :label="$t('performance_bar.period')"
            class="text-left"
            style="min-width: 200px;"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" @click="onClick(item)" :title="''" class="d-flex align-center" density="compact">
                {{ item.title }}
                <v-icon size="20" v-if="item.value === PerformancePeriod.Custom">
                  mdi-calendar
                </v-icon>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <div id="performance-group-by-select" v-if="isSummary">
          <GroupByField v-model="groupBy" />
        </div>
        <div class="d-flex flex-row align-center flex-end" v-if="isIntervalsEnabled">
          <v-select
            :label="'Intervall'"
            v-model="interval"
            :items="intervalOptions"
            hide-details
            variant="outlined"
            style="min-width: 200px;"
          />
        </div>
        <div id="chart-type-select" v-if="isChartTypeEnabled">
          <v-select
            :label="$t('performance_bar.chart_type')"
            v-model="chartType"
            :items="chartTypeOptions"
            hide-details
            class="text-left"
            variant="outlined"
            style="min-width: 200px;"
            id="hart-type-select"
          />
        </div>
      </div>
      <div class="d-flex flex-column align-center flex-sm-row flex-end" v-if="isMonetaryValueEnabled">
        <button-toggle
          full-on-mobile
          v-model="showMonetaryValue"
          density="comfortable"
          :options="[{
            title: $t('performance_bar.monetary'),
            value: true
          }, {
            title: $t('performance_bar.percentage'),
            value: false
          }]"
          id="monetary-value-toggle"
        />  
        <v-switch
          v-if="isOpenPositionsFeatureEnabled"
          :label="showOpenAndClosedPositions ? $t('performance_bar.all_positions') : $t('performance_bar.open_positions')"
          v-model="showOpenAndClosedPositions"
          hide-details
        />
      </div>
    </div>
  </v-card>
  <v-dialog :max-width="600" v-model="showRangePicker" :scrollable="true">
    <DisplayCard>
      <div class="pa-5">
        <v-row>
          <v-col cols="12">
            <h3 class="text-h5">{{ $t('performance_bar.period') }}</h3>
          </v-col>
          <v-col cols="12" class="d-flex ga-2">
            <DateField
              :label="$t('label.start_date')"
              v-model="rangeForm.startDate"
              v-bind="fieldDesign"
            />
            <DateField
              :label="$t('label.end_date')"
              v-model="rangeForm.endDate"
              v-bind="fieldDesign"
            />
          </v-col>
          <v-col cols="12">
            <v-btn @click="chooseRange" color="blue">
              {{ $t('button.choose') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </DisplayCard>
  </v-dialog>
</template>