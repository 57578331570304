<script setup lang="ts">
import { currencyFormatter, percentFormatter, colors } from '@/const';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useReport } from '@/report.helper';
import { useAppStore } from '@/stores/app';
import { allocationChartTypes, useDiversityChart } from '@/utils/allocation.utils';

const props = defineProps({
  report: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();
const appStore = useAppStore();

const chartInput = computed(() => ({
  height: chartType.value === 'waterfall' ? 400 : 320,
  compact: true,
  valueKey: 'value',
  dataLabels: false,
  name: '',
  mode: 'allocation',
  currencyCode: props.report.currencyCode,
  pointFormat: '<b>{point.y}</b>',
  centerText: () => appStore.hideBalances ? '' : `<strong class="balance">${currencyFormatter(props.report.value, props.report.currencyCode, 0, 0)}</strong><br /><span class="font-weight-thin text-caption balance">${t('label.value')}</span>`
}));

const { groupsFlattened } = useReport(computed(() => props.report));
const { chartType, chart } = useDiversityChart(groupsFlattened, chartInput);

const colSize = computed(() => {
  return {
    lg: chartType.value === 'waterfall' ? 12 : 6
  }
});

const total = computed(() => {
  return props.report.value;
})

const items = computed(() => {
  return groupsFlattened.value
    .filter(x => x.value > 0)
    .slice(0, 7)
    .map((x, n) => ({
      title: x.title || x.name,
      value: x.value,
      color: colors[n],
      allocation: x.value / total.value,
      currencyCode: props.report?.currencyCode,
    }));
});

defineExpose({ colSize });
</script>

<template>
  <DisplayCard :title="props.title" class="overflow-hidden">
    <template v-slot:actions>
      <div class="d-flex ga-2 align-center">
        <v-btn 
          color="primary"
          variant="tonal"
          v-if="$vuetify.display.smAndUp"
          :to="{
            name: 'diversity-report',
            params: {
              portfolioId: report.portfolioId || 0
            },
          }">{{ $t('label.view_report') }}</v-btn>
        <ButtonToggle
          v-model="chartType"
          mode="icon"
          density="compact"
          :options="allocationChartTypes"
          no-columns
        />
      </div>
    </template>
    <v-row>
      <v-col cols="12" :md="chartType === 'pie' && !isLoading ? 6 : 12">
        <highcharts
          :options="chart"
          v-if="groupsFlattened.length > 0 && !isLoading"
          :key="chartType + '-' + appStore.hideBalances"
          :class="{'mt-4': chartType === 'waterfall'}"
        />
        <div v-else class="ma-4">
          <v-skeleton-loader :boilerplate="!props.isLoading" height="280" style="width:100%;" type="ossein" />
        </div>
      </v-col>
      <v-col cols="12" md="6" v-if="chartType === 'pie' && !isLoading">
        <v-list density="compact" lines="one">
          <v-list-item v-for="(item, n) in items" :title="item.title" density="compact" class="py-0">
            <template v-slot:prepend>
              <v-avatar size="24" rounded="md" :color="item.color" />
            </template>
            <template v-slot:subtitle>
              <span class="balance">
                {{ currencyFormatter(item.value, item.currencyCode) }}
              </span>
            </template>
            <template v-slot:append>
              {{ percentFormatter(item.allocation) }}
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </DisplayCard>
</template>