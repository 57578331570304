import { computed, type ComputedRef, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";
import { currencyFormatter, percentFormatter, colors } from '@/const'
import { useAppStore } from "./stores/app";

const defOpts = computed(() => ({ valueKey: 'percentage', height: 350 }))

function getUniqueColor(index, tone = 0) {
  const numColors = colors.length;

  if (index < numColors) {
    return colors[index]; // Use the predefined color
  }

  // Generate a new unique color (e.g., using HSL)
  const hue = (index * 137.508) % 360; // Golden angle approximation
  return `hsl(${hue}, 70%, 50%)`;
}

function getRangeColour(value: number) {
  if (value >= 3) {
    return '#30cc5a';
  } else if (value >= 2) {
    return '#2f9e4f';
  } else if (value >= 1) {
    return '#35764e';
  } else if (value >= 0.5) {
    return '#3d5451';
  } else if (value >= 0.1) {
    return '#3f4c54';
  } else if (value <= -3) {
    return '#f63538';
  } else if (value <= -2) {
    return '#bf4045';
  } else if (value <= -1) {
    return '#8b444e';
  } else if (value <= -0.5) {
    return '#4f4554';
  } else {
    return '#414554';
  }
}

function formatCode (code: string) {
  return code?.split(':')?.[0]
}

function formatReturn (value: number) {
  let number = value.toFixed(2) + '%'
  if (value > 0) {
    number = '+' + number
  }
  return number
}

function nameFormatter (item: any) {
  if (item.consolidate === 'consolidate') {
    const allSame = item.consolidation?.every(i => i.code === item.code)
    if (allSame) {
      return formatCode(item.code)
    }
  }
  let name = (item.consolidate === 'instrument' && item.instrumentType !== 'cash' ? formatCode(item.code) : item.title) ?? item.name
  if (name.length > 100) {
    name = name.substring(0, 7).trim() + '...'
  }
  return name
}

function mapTreemapLevelItem (item: any, valueKey: string, opts: any, n: number, i: number = 0, hasChildren: boolean = false) {
  const color = opts.mode === 'performance'
    ? getRangeColour(item.openAndClosedPositions.totalReturn * 100)
    : getUniqueColor(n, i)

  return {
    name: nameFormatter(item),
    title: item.title || item.name,
    value: item[valueKey],
    color: hasChildren ? 'transparent' : color,
    states:{
      hover: {
        fill: color
      }
    },
    totalReturn: item.openAndClosedPositions?.totalReturn * 100,
    ppp: item.percentage
  }
}

export function addLogo (chart: any, isDark: boolean) {
  // Add the logo image
  chart.renderer.image(
    `https://static.portfellow.com/portfellow-${isDark ? 'w' : 'b'}.svg?v=3`, // URL of the logo image
    chart.chartWidth - 110, // X position (adjust as needed)
    chart.chartHeight - 25, // Y position (adjust as needed)
    100, // Width of the logo
    25 // Height of the logo
  )
}

export function useAllocationChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string,
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
}> = defOpts) {
  const { t } = useI18n();
  const theme = useTheme();

  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value.filter((group: any) => group.value > 0);
    const isDark = theme.current.value.dark;
    const isPercentage = opts.isPercentage ?? false;

    return {
      chart: {
        type: 'pie',
        height: opts.height,
        backgroundColor: 'transparent',
        events: {
          load: function() {
            addLogo(this);
          },
          fullscreenOpen: function() {
            // Change background color when fullscreen is opened
            this.update({
                chart: {
                    backgroundColor: isDark ? '#00000' : '#FFFFFF' // Fullscreen chart background color
                }
            });
          },
          fullscreenClose: function() {
              // Revert background color when fullscreen is closed
              this.update({
                  chart: {
                      backgroundColor: 'transparent' // Revert to original background color
                  }
              });
          },
          render: function () {
              var chart = this,
                  centerX = chart.plotLeft + (chart.plotWidth / 2),
                  centerY = chart.plotTop + (chart.containerBox.height / 2) - 10;

              // Remove existing text (to avoid duplication on redraw)
              if (chart.centerText) {
                  chart.centerText?.destroy();
              }

              // Add text in the center
              chart.centerText = chart.renderer.text(opts.centerText?.(), centerX, centerY)
                  .attr({
                      'text-anchor': 'middle', // Center the text
                      zIndex: 5
                  })
                  .css({
                    fontSize: '20px',
                    color: isDark ? 'white' : '#0C4160'
                  })
                  .add();
          },
        }
      },
      exporting: {
        enabled: opts.exporting ?? false,
        buttons: {
          contextButton: {
            align: 'right',  // Horizontal alignment (left, center, right)
            verticalAlign: 'top',  // Vertical alignment (top, middle, bottom)
            x: -10,  // Horizontal offset
            y: 0  // Vertical offset
          }
        },
        chartOptions: {
          chart: {
            backgroundColor: '#FFFFFF',
          }
        }
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: opts.pointFormat ?? (isPercentage ? `{series.name}: <b>{point.percentage:.1f}%</b>` :  '{series.name}: <b>{point.value}</b>'),
        pointFormatter: function () {
          let value = '';
          if (isPercentage) {
            value = percentFormatter(this.percentage / 100)
          } else {
            value = currencyFormatter(this.y, opts.currencyCode)
          }

          return `<b>${value}</b>`
        }
      },
      legend: {
        enabled: !opts.compact,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemWidth: opts.compact ? 100 : undefined,
        floating: false,
        itemStyle: {
          color: isDark ? 'white' : '#0C4160'
        },
        x: 0,
        y: 20
      },
      plotOptions: {
        pie: {
          innerSize: '60%',
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: opts.dataLabels ?? true,
            format: '{point.percentage:.1f}%',
            style: {
              color: isDark ? 'white' : '#0C4160',
              fontSize: '16px'
            }
          },
          showInLegend: true,
        }
      },
      navigation: {
        buttonOptions: {
            verticalAlign: 'bottom',
            y: 0
        }
      },
      series: [{
          name: opts.name ?? t('report.percentage'),
          data: list.map((group: any) => {
            const dashed = group.value < 0
            const y = Math.round(group[opts.valueKey] * (isPercentage ? 10000 : 1)) / (isPercentage ? 100 : 1)

            return {
              name: group.title ?? group.name,
              dashStyle: dashed ? 'longdash' : undefined,
              borderColor: dashed ? 'red' : undefined,
              color: dashed ? 'rgba(255, 0, 0, 0.5)' : undefined,
              y: Math.abs(y),
              value: y
            }
          })
      }]
    }
  });

  return {
    chartOptions
  }
}

export function useWaterfallChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string,
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
}> = defOpts) {
  const { t } = useI18n();
  const theme = useTheme();
  const app = useAppStore();

  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value;
    const isDark = theme.current.value.dark;
    const isPercentage = opts.isPercentage ?? false;

    const items = list.map((group: any) => {
      const y = Math.round(group[opts.valueKey] * (isPercentage ? 10000 : 1)) / (isPercentage ? 100 : 1)

      return {
        name: group.title ?? group.name,
        color: y < 0 ? colors[5] : colors[0],
        y: y,
        value: y
      }
    });

    const total = items.reduce((acc, item) => acc + item.y, 0);

    return {
      chart: {
        type: 'waterfall',
        height: opts.height,
        backgroundColor: 'transparent'
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        pointFormat: opts.pointFormat ?? (isPercentage ? `{series.name}: <b>{point.percentage:.1f}%</b>` :  '{series.name}: <b>{point.value}</b>'),
        pointFormatter: function () {
          let value = '';
          if (isPercentage) {
            value = percentFormatter(this.percentage / 100)
          } else {
            value = currencyFormatter(this.y, opts.currencyCode)
          }

          return `<b>${value}</b>`
        }
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {
          enabled: true,
          style: {
            color: isDark ? '#ffffff' : '#0C4160'
          }
        },
        gridLineColor: isDark ? '#32323e' : 'rgb(222, 231, 248)',
        lineColor: isDark ? '#495057' : 'rgb(222, 231, 248)'
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: true,
          style: {
            color: isDark ? '#ffffff' : '#0C4160'
          }
        },
        lineColor: isDark ? '#495057' : 'rgb(222, 231, 248)',
        lineWidth: 1,
        gridLineColor: isDark ? '#495057' : 'rgb(222, 231, 248)',
        minorGridLineWidth: 0,
      },
      series: [{
          name: opts.name ?? t('report.percentage'),
          data: items.filter(i => i.y > 0).concat([{
            name: 'Brutoväärtus',
            isSum: true,
            color: colors[1]
          }]).concat(items.filter(i => i.y < 0)).concat([{
            name: 'Netoväärtus',
            isIntermediateSum: true,
            color: colors[3]
          }]),
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.point.isSum || this.point.isIntermediateSum || this.y < 0) {
                if (app.hideBalances) {
                  return '?';
                }
                return currencyFormatter(this.y, opts.currencyCode, 0, 0)
              }
              return percentFormatter(this.y / total)
            }
          },
          pointPadding: 0
      }]
    }
  });

  return {
    chartOptions
  }
}

export function useTreemapChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string,
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
  mode?: 'allocation' | 'performance'
}> = defOpts) {
  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value ?? [];
    let points = [];
    try {
    points = list.reduce((collect: any, group: any, n: number) => {
      collect.push({
        id: group.key,
        ...mapTreemapLevelItem(group, opts.valueKey, _opts.value, n, undefined, group.items?.length),
      });
      if (group.items?.length) {
        group.items.forEach((item: any, i: number) => {
          collect.push({
            parent: group.key,
            id: `${group.key}-${i}`,
            ...mapTreemapLevelItem(item, opts.valueKey, _opts.value, n, i, item.items?.length),
          });
          if (item.items?.length) {
            item.items.forEach((subitem: any, j: number) => {
              collect.push({
                parent: `${group.key}-${i}`,
                id: `${group.key}-${i}-${j}`,
                ...mapTreemapLevelItem(subitem, opts.valueKey, _opts.value, n, j, false),
              });
            });
          }
        });
      }
      return collect;
    }, []);
  } catch (e) { 
    console.log('error', e)
  }

    return {
      chart: {
        type: 'treemap',
        height: opts.height,
        backgroundColor: 'transparent',
        margin: [0,0,0,0],
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0)',
        shadow: false,
        borderWidth: 0,
        useHTML: true,
        style: {
          zIndex: 100
        },
        formatter: function(){
          const value = currencyFormatter(this.point.value, opts.currencyCode)
          return '<p style="color:#9ab;border-radius:3px;font-size:11px;text-align:left;margin:0;padding:10px;border:1px solid black;background-color:#151519">' + 
            '<strong style="color:#99a5bb;font-weight:normal;font-size:14px;"><strong>'+ this.point.title +'</strong><br>Väärtus: ' + value + '<br>Osakaal: ' + ((this.point.ppp * 100).toFixed(2)) + '%<br>Tootlus: ' + formatReturn(this.point.totalReturn) + '</p>';
        }
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        treemap: {
          borderRadius: 1,
          dataLabels: {
            enabled: true,
          },
        }
      },
      series: [{
        name: 'Portfolio',
        type: 'treemap',
        breadcrumbs: {
          floating: true,
          buttonTheme: {
            style: {
              color: "#fff",
            },
            states: {
              hover: {
                style: {
                  color: "#fff",
                },
                fill: "#fdd714"
              },
              select: {
                style: {
                  color: "#fff",
                }
              }
            }
          },
        },
        states: {
          hover: {
            borderColor: '#fdd714',
          }
        },
        data: points,
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        borderWidth: 1,
        borderColor: '#000',
        clip: false,
        dataLabels: {
          position: 'center',
          verticalAlign: 'middle',
          useHTML: true,
          enabled: true,
          allowOverlap: true,
          padding: 2,
          style: {
            color: '#fff',
          },
          formatter: function() {
            if (!this.point.shapeArgs) {
              return;
            }
            if (this.point.node.children.length > 0) {
              if (this.point.node.children.length <= 1) {
                return '';
              }
              return '<div class="category-label">' + this.key + '</div>';
            }
            if (this.point.shapeArgs.width < 20 || this.point.shapeArgs.height < 10) {
              return '';
            }
            const val = opts.mode === 'performance' ? formatReturn(this.point.totalReturn) : '';
            if(this.point.shapeArgs.width > this.point.shapeArgs.height) {
              return '<div style="width:' + (this.point.shapeArgs.width - 4) +'px;" class="main-label"><div class="main-label__title" style="font-size:' + ((this.point.shapeArgs.height)/6) + 'px">' + this.key + '</div><div class="main-label__changes" style="font-size:' + ((this.point.shapeArgs.height)/6) + 'px">' + val + '</div></div>';
            } else {
              return '<div style="width:' + (this.point.shapeArgs.width - 4) +'px;"  class="main-label"><div class="main-label__title" style="font-size:' + ((this.point.shapeArgs.width)/6) + 'px">' + this.key + '</div><div class="main-label__changes" style="font-size:' + ((this.point.shapeArgs.width)/6) + 'px">' + val + '</div></div>';
            }
          }
        },
        levels: [{
          level: 1,
          dataLabels: {
            enabled: true,
            zIndex: 1,
          },
          borderWidth: 4,
          levelIsConstant: false
        }, {
          level: 2,
          dataLabels: {
            zIndex: 2,
            style: {
              fontSize: '1px'
            }
          }
        }, {
          level: 3,
          dataLabels: {
            zIndex: 3,
            style: {
              fontSize: '1px'
            }
          }
        }],
        accessibility: {
          exposeAsGroupOnly: true
        },
      }]
    }
  });

  return {
    chartOptions
  }
}