<script setup lang="ts">
import DisplayCard from '@/components/shared/DisplayCard.vue';
import ValueWithArrow from '@/components/shared/ValueWithArrow.vue';
import { currencyFormatter, dateFormatter } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useReportStore } from '@/stores/reportStore';
import { watch } from 'vue';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ValueChart from '@/components/ValueChart.vue';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import HelpIcon from '@/components/HelpIcon.vue';
import { usePerformanceFilter } from '@/filter.helper';
import HideBalancesButton from '@/components/HideBalancesButton.vue';
import BarChart from '@/components/BarChart.vue';
import { useFutureCashflowReport } from '@/utils/chart.utils';
import Avatar from '@/components/Avatar.vue';
import TransactionTypeCell from '@/components/table/TransactionTypeCell.vue';
import PriceCell from '@/components/table/PriceCell.vue';
import AssetAllocation from './AssetAllocation.vue';
import Performance from './Performance.vue';

const portfolioStore = usePortfolioStore();
const reportStore = useReportStore();
const router = useRouter();
const route = useRoute();

const isLoading = ref(true);
const isNetworthLoading = ref(true);
const isFutureIncomeLoading = ref(true);
const assetAllocationChart = ref<typeof AssetAllocation | null>(null);

const summary = ref<any>({});
const netWorth = ref<any>([]);
const futureIncome = ref<any>([]);
const valueIs = ref('value');

const { summaryPeriod, summaryRange, summaryGroupBy } = usePerformanceFilter()
const { chartData, chartCategories } = useFutureCashflowReport(futureIncome)

watch(() => route.params.portfolioId, async newId => {
  if (newId) {
    portfolioStore.select(newId as any as number);
  }
}, { immediate:true });

watch(() => portfolioStore.selectedPortfolio, async portfolio => {
  if (portfolio?.hasAccounts === false) {
    router.push({ name: 'onboarding', params: { portfolioId: portfolioStore.selectedPortfolioId } });
  }
}, { immediate:true });

async function loadSummary() {
  if (!portfolioStore.selectedPortfolioId) {
    return
  }
  isLoading.value = true;
  isNetworthLoading.value = true;
  try {
    portfolioStore.getSummary(portfolioStore.selectedPortfolioId, summaryPeriod.value, summaryRange.value, summaryGroupBy.value)
      .then((data) => {
        summary.value = data;
      })
      .finally(() => {
        isLoading.value = false;
      });
    reportStore.getNetworthReport(portfolioStore.selectedPortfolioId, { period: summaryPeriod.value, ...summaryRange.value }).then((data) => {
      netWorth.value = data;
    }).finally(() => {
      isNetworthLoading.value = false;
    });
  } finally {
  }
}

async function loadFutureIncomeReport() {
  try {
    if (!portfolioStore.selectedPortfolioId) {
      return
    }
    isFutureIncomeLoading.value = true;
    reportStore.getFutureFashflowReport(portfolioStore.selectedPortfolioId, {
      period: 'next_6_months',
      interval: 'month',
      page: 1,
      pageSize: 7
    }).then((data) => {
      futureIncome.value = data;
    }).finally(() => {
      isFutureIncomeLoading.value = false;
    });
  } finally {

  }
}

watch(() => portfolioStore.selectedPortfolioId, async () => {
  await loadSummary();
  await loadFutureIncomeReport();
}, { immediate: true });
watch(() => [summaryPeriod, summaryRange, summaryGroupBy], loadSummary, { deep: true });

const hasPortfolios = computed(() => {
  return portfolioStore.portfolios.value?.length > 0
})

const isPortfoliosLoading = computed(() => {
  return portfolioStore.portfolios.loading
})
</script>

<template>
  <template v-if="hasPortfolios">
    <v-row>
      <v-col cols="12" v-if="summary.isPartial">
        <PartialReportAlert />
      </v-col>
      <v-col cols="12">
        <PerformanceFilters
          v-model="summaryPeriod"
          v-model:groupByValue="summaryGroupBy"
          v-model:range="summaryRange"
          :is-summary="true"
        />
      </v-col>
      <v-col cols="12" md="12">
        <div class="d-flex align-center justify-space-between">
          <div>
            <h1 style="font-size: 2.5em;letter-spacing:-0.03em;" class="font-weight-black text-darkprimary d-flex align-center">
              <span v-if="!isLoading" class="balance-lg">{{ currencyFormatter(summary?.value, summary?.currencyCode) }}</span>
              <v-progress-circular v-else size="24" indeterminate color="primary" class="mr-2" />
              <HideBalancesButton />
            </h1>
            <div class="d-flex ga-1 text-darkprimary text-body-1 mt-n1 align-center">
              <ValueWithArrow :value="summary?.openAndClosedPositions?.totalReturn ?? 0" :arrow="true" :color-text="false" />
              <HelpIcon color="darkprimary" :help-text="$t('help.performance')" />
              <span class="balance">({{ currencyFormatter(summary?.openAndClosedPositions?.totalGain, summary?.currencyCode) }})</span>
            </div>
          </div>
          <div v-if="$vuetify.display.smAndUp">
            <ButtonToggle v-model="valueIs" density="comfortable" :options="[{
              title: $t('label.networth'),
              value: 'value'
            }, {
              title: $t('label.costbase'),
              value: 'costbase'
            }]" />
          </div>
        </div>
        <ValueChart
          :values="netWorth"
          :is-loading="isNetworthLoading"
          :currency-code="summary?.baseCurrencyCode"
          :value-key="valueIs"
          :portfolio="portfolioStore.selectedPortfolio"
        />
        <div class="mt-5"></div>
      </v-col>
      <v-col cols="12" :lg="assetAllocationChart?.colSize?.lg" class="d-flex align-stretch">
        <AssetAllocation
          ref="assetAllocationChart"
          :report="summary"
          :is-loading="isNetworthLoading"
          :title="$t('label.asset_allocation')"
        />
      </v-col>
      <v-col cols="12" :lg="assetAllocationChart?.colSize?.lg === 12 ? 12 : 6" class="d-flex align-stretch">
        <Performance :report="summary" :is-loading="isLoading" />
      </v-col>
      <v-col cols="12" sm="12" md="12" class="d-flex align-stretch" v-if="isFutureIncomeLoading || futureIncome?.transactions?.length">
        <DisplayCard :title="$t('label.future_cashflow')" class="overflow-hidden">
          <template v-slot:actions>
            <v-btn color="primary" variant="tonal" :to="{
              name: 'future-cashflow-report',
              params: {
                portfolioId: portfolioStore.selectedPortfolioId || 0
              },
              query: {
                period: 'next_6_months',
                interval: 'month'
              }
            }">{{ $t('label.view_report') }}</v-btn>
          </template>
          <v-row>
            <v-col cols="12" md="7" class="pl-5">
              <BarChart
                class="mt-2"
                :values="chartData"
                :categories="chartCategories"
                :isLoading="isLoading"
                v-if="!isFutureIncomeLoading"
              />
              <div v-else class="px-5 pb-5">
                <v-skeleton-loader :boilerplate="!isNetworthLoading" height="280" style="width:100%;" type="ossein" />
              </div>
            </v-col>
            <v-col cols="12" md="5">
              <v-list>
                <v-list-item v-for="tx in futureIncome.transactions" :title="tx.holding?.name">
                  <template v-slot:prepend>
                    <Avatar :code="tx.holding?.instrument?.symbol" :logo="tx.holding?.instrument?.logo" :size="40" />
                  </template>
                  <template v-slot:subtitle>
                    <div class="d-flex align-center ga-2">
                      {{ dateFormatter(tx.dateTransactionAt) }}
                    </div>
                  </template>
                  <template v-slot:append>
                    <div class="d-flex flex-column align-end">
                      <PriceCell
                        :value="tx.totalValue"
                        :transactionType="tx.transactionType"
                        :currency-code="tx.currencyCode"
                        :maximumFractionDigits="2"
                        is-total-value
                      />
                      <TransactionTypeCell 
                        :value="tx.transactionType" 
                        :transaction="tx" 
                        is-cashflow 
                        hide-icon
                        :upper-case="false"
                        class="text-body-1"
                      />
                    </div>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </DisplayCard>
      </v-col>
  </v-row>
</template>
  <div v-else-if="isPortfoliosLoading">
    <v-row>
      <v-col cols="12">
        <v-progress-linear indeterminate color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>
