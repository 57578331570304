<template>
  <div class="d-flex flex-column ga-2 mb-4">
    <div class="d-flex ga-2 flex-column-reverse flex-sm-row align-center">
      <div :style="$vuetify.display.smAndUp ? '' : 'width:100%;'">
        <button-toggle
          v-model="isInvestmentTransaction"
          :options="typeOptions"
          density="compact"
          :disabled="!editable"
          no-columns
        />
      </div>
      <div class="text-body-1">
        <div class="font-weight-bold text-h4">Investeeringuga seotud tehing?</div>
        <div style="line-height:1em;">Kas tehing on seotud rahakontoga (näiteks: sisse/väljakanne, konto intress, teenustasu) või investeeringuga (näiteks: ost, müük, dividendid)?</div>
      </div>
    </div>
    <div class="d-flex ga-2 flex-column-reverse flex-sm-row align-center" v-for="item in listItems">
      <div :style="$vuetify.display.smAndUp ? 'width:210px;' : 'width:100%;'">
        <v-btn v-if="item.showAction" @click="item.action" :color="item.buttonColor" :disabled="item.disabled" block>
          {{ item.buttonText }}
        </v-btn>
      </div>
      <div class="d-flex align-sm-center justify-space-between" :style="$vuetify.display.smAndUp ? '' : 'width:100%;'">
        <div>
          <div>
            <div class="font-weight-bold text-h4">{{ item.title }}</div>
            <div class="d-flex align-center">
              <AvatarVue v-if="item.logo" :logo="item.logo" :code="item.symbol ?? ''" :size="16" class="mr-1" />
              <span :class="{'text-red': !item.isValid}">{{ item.subtitle() }}</span>
            </div>
          </div>
        </div>
        <v-btn
          :color="item.iconColor"
          :icon="item.icon"
          variant="text"
          :disabled="true"
        />
      </div>
    </div>
  </div>
  <div class="d-flex ga-2 flex-column">
    <v-alert
      v-if="instrumentCurrencyNotSame"
      :prominent="true"
      color="warning"
      theme="dark"
      border
    >
      Valitud instrumendi ja imporditud tehingu valuutad ei ole samad. Palun veendu kas valitud instrument on õige.
      Pangad kasutavad vahel populaarset sümbolit, kuigi tegelikult on alusvara kaubeldav teises valuutas ning on noteeritud teise sümboliga.
    </v-alert>
    <v-alert v-if="extraInfo">
      <span v-html="extraInfo" />
    </v-alert>
  </div>
</template>

<script setup lang="ts">
import AvatarVue from '@/components/Avatar.vue';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import { computed, defineProps, ref, watch } from 'vue';
import { useInstrumentStore } from '@/stores/instrumentStore';
import { usePortfolioStore } from '@/stores/portfolioStore';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  editable: {
    type: Boolean,
    required: false,
    default: true
  },
  cashAccount: {
    type: Object,
    required: false,
    default: () => ({})
  },
  portfolioId: {
    type: Number,
    required: false,
    default: 0
  },
  showUndefined: {
    type: Boolean,
    required: false,
    default: false
  }
});

const emit = defineEmits(['create', 'update:modelValue']);

const instrumentStore = useInstrumentStore();
const portfolioStore = usePortfolioStore();

const item = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const instrumentDetails = ref();

const listItems = computed(() => {
  const hasInstrument = !!item.value.instrument.id;

  return [
    {
      title: 'Investeering',
      subtitle: () => {
        const text = item.value.instrument.name ?? item.value.instrument.symbol;
        if (!text) {
          return 'Vali investeering';
        } else {
          return text + ' (' + item.value.instrument.currencyCode + ')';
        }
      },
      action: () => {
        emit('create');
      },
      isValid: hasInstrument,
      showAction: true,
      logo: hasInstrument ? item.value.instrument?.logo || instrumentDetails.value?.logo : null,
      symbol: hasInstrument ? item.value.instrument?.symbol : null,
      buttonText: hasInstrument ? 'Muuda' : 'Otsi',
      buttonColor: hasInstrument ? 'secondary' : 'primary',
      visible: isInvestmentTransaction.value && isInvestmentTransaction.value !== 'none',
      disabled: !props.editable
    }
  ].map((item) => {
    return {
      ...item,
      icon: item.isValid ? 'mdi-check' : 'mdi-close',
      iconColor: item.isValid ? 'success' : 'error',
    }
  }).filter((item) => item.visible);
});

const instrument = ref({ type: 'investment' });
const counterpartyId = ref<number | undefined>(undefined);

const typeOptions = computed(() => {
  return [{
    title: 'Investeering',
    value: true,
    visible: true
  }, {
    title: 'Konto',
    value: false,
    visible: true
  }, {
    title: 'Määramata',
    value: 'none',
    visible: props.showUndefined
  }].filter((item) => item.visible);
})

const isInvestmentTransaction = computed({
  get() {
    if (props.showUndefined) {
      if (item.value.instrument.type === 'cash') {
        return false;
      } else if (item.value.instrument.type && item.value.instrument.type !== 'cash') {
        return true;
      } else {
        return 'none';
      }
    }
    return item.value.instrument.type !== 'cash';
  },
  set(_val) {
    if (_val === 'none') {
      item.value.instrument = {};
    } else if (!_val) {
      instrument.value = item.value.instrument;
      counterpartyId.value = item.value.counterparty?.id;
      item.value.instrument = props.cashAccount || {};
      item.value.counterparty.id = 0;
    } else {
      item.value.instrument = instrument.value;
      item.value.counterparty.id = counterpartyId.value || props.cashAccount?.id || 0;
    }
  }
});

watch(() => item.value.instrument.id, async (id) => {
  if (id && parseInt(id.toString()) > 0 && item.value.instrument.type !== 'cash') {
    instrumentDetails.value = await instrumentStore.getById(id)
  }
});

const extraInfo = computed(() => {
  const hasInstrumentDetails = !!instrumentDetails.value;
  if (!hasInstrumentDetails) {
    return;
  }
  const isAlreadyInPortfolio = !!instrumentDetails.value.portfolios.find((p) => p.id === props.cashAccount.portfolioId);
  const inPortfolios = instrumentDetails.value.portfolios.map((p) => p.name).join(', ');

  if (!isAlreadyInPortfolio) {
    if (inPortfolios) {
      return `Investeering on juba portfellis: <strong>${inPortfolios}</strong> ja see lisatakse ka <strong>${portfolioStore.selectedPortfolio.name}</strong> portfelli.`;
    } else if (isInvestmentTransaction.value) {
      return `Uus investeering lisatakse <strong>${portfolioStore.selectedPortfolio.name}</strong> portfelli.`;
    }
  }
});

const instrumentCurrencyNotSame = computed(() => {
  const instrumentCurrencyCode = item.value.instrument?.currencyCode;
  const accountCurrencyCode = item.value.account?.currencyCode;

  const isDifferent = instrumentCurrencyCode && accountCurrencyCode && instrumentCurrencyCode !== accountCurrencyCode;

  return isDifferent && isInvestmentTransaction.value;
});
</script>