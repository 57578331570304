<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import TopList from './TopList.vue';
import { useReport } from '@/report.helper';
import { useTreemapChart } from '@/chart.helper';
import { useAuthStore } from '@/stores/authStore';

const props = defineProps({
  report: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();

const isPercent = ref<boolean | string>(authStore.getUserPreference(`performance.type`, true));
const { groupsFlattened } = useReport(computed(() => props.report));
const { chartOptions: chart } = useTreemapChart(groupsFlattened, computed(() => ({
  height: 320,
  valueKey: 'value',
  mode: 'performance'
})));

watch(() => isPercent.value, (value) => {
  authStore.setUserPreference(`performance.type`, value);
});

</script>

<template>
  <DisplayCard :title="$t('label.performance')" style="overflow: hidden;">
    <template v-slot:actions>
      <ButtonToggle
        v-model="isPercent"
        mode="icon"
        density="compact"
        :options="[{
            title: 'mdi-percent-outline',
            value: true
          }, {
            title: 'mdi-numeric',
            value: false
          }, {
            title: 'mdi-chart-tree',
            value: 'tree'
          }]"
        no-columns
      />
    </template>
    <v-row>
      <template v-if="isPercent !== 'tree'">
        <v-col cols="12" sm="6">
          <TopList
            :title="$t('label.best_performance')"
            type="top"
            :report="report"
            :is-loading="isLoading"
            :is-percent="!!isPercent"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <TopList
            :title="$t('label.worst_performance')"
            type="bottom"
            :report="report"
            :is-loading="isLoading"
            :is-percent="!!isPercent"
          />
        </v-col>
      </template>
      <v-col v-else>
        <highcharts
          :options="chart"
          v-if="groupsFlattened.length > 0 && !isLoading"
        />
      </v-col>
    </v-row>
  </DisplayCard>
</template>